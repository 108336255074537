export function createToast({ detail: { type, message, title, coins } }) {
    if (type === 'error') {
        window.toastr.error(message, title);
        return;
    }

    const container = $('#toast-container').length ? $('#toast-container') : window.toastr.getContainer(false, true);
    let imageSrc = '/images/coins/coins_stack.svg';

    if ([1, 5, 10, 15, 20, 50, 75, 100].includes(coins)) {
        imageSrc = `/images/coins/coin_${coins}.png`;
    }

    const toast = $('<div/>').html(`
        <div class="toast fade mx-auto toast-light toast-fixed p-2"
            role="alert"
            aria-atomic="true"
            data-mdb-autohide="true"
            data-mdb-delay="2000"
            data-mdb-position="top-right"
            data-mdb-stacking="true"
            data-mdb-color="light"
        >
            <div class="toast-body toast-light d-flex flex-row">
                <div class="col-2 p-0 m-auto">
                    <img class="w-100" src="${imageSrc}" />
                </div>
                <div class="col-10">
                    ${message}
                </div>
            </div>
        </div>
    `).contents();

    toast.hide();
    container.append(toast);

    toast.fadeIn(
        300,
        'swing',
        () => setTimeout(() => toast.fadeOut(
            1000,
            () => toast.remove(),
        ), 5000),
    );
}
