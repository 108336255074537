
export function randomPassword()
{
    const length = 12;
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChar = "!@#$%^&*()-_=+;:,.<>?";
    const allChar = lowercase + uppercase + numbers + specialChar;
    let pass = "";

    pass += uppercase[Math.floor(Math.random() * uppercase.length)];
    pass += lowercase[Math.floor(Math.random() * lowercase.length)];
    pass += numbers[Math.floor(Math.random() * numbers.length)];
    pass += specialChar[Math.floor(Math.random() * specialChar.length)];

    for (let x = pass.length; x < length; x++) {
        pass += allChar[Math.floor(Math.random() * allChar.length)];
    }
    pass = pass.split('').sort(() => 0.5 - Math.random()).join('');

    return pass;
}
