import { VideoPlayerType } from "./video-player-type";

export class Request {
    /**
     * @type {string|null|undefined}
     */
    event;

    /**
     * @type {string|null|undefined}
     */
    eventType;

    /**
     * @type {string|null|undefined}
     */
    modelClass;

    /**
     * @type {string|number|null|undefined}
     */
    modelId;

    /**
     * @type {boolean}
     */
    subscribes;

    /**
     * @type {number|null|undefined}
     */
    delay;

    /**
     * @type {HTMLElement|null|undefined}
     */
    intersectionElement;

    /**
     * @type {HTMLElement|null|undefined}
     */
    videoPlayer;

    /**
     * @type {VideoPlayerType}
     */
    videoPlayerType;

    /**
     * @type {HTMLAudioElement|null|undefined}
     */
    audioPlayer;

    /**
     * @type {Array<any>}
     */
    audioFiles;

    /**
     * @type {Object<any>|Array<any>}
     */
    additional;

    constructor(params = {}) {
        Object.assign(this, params);
    }
}

/**
 * Creates a new request object
 *
 * @param {Object<any>} args
 * @returns {Request}
 */
export function createRequest({
    modelClass = undefined,
    modelId = undefined,
    event = undefined,
    eventType = undefined,
    intersectionElement = undefined,
    delay = undefined,
    subscribes = false,
    videoPlayer = undefined,
    videoPlayerType = VideoPlayerType.YouTube.name,
    audioPlayer = undefined,
    audioFiles = [],
    additional = [],
}) {
    return new Request({
        modelClass,
        modelId,
        event,
        eventType,
        intersectionElement,
        delay,
        subscribes,
        videoPlayer,
        videoPlayerType: new VideoPlayerType(videoPlayerType),
        audioPlayer,
        audioFiles: Array.from(audioFiles),
        additional,
    });
}
