import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeDe from 'dayjs/locale/de';
import localeEn from 'dayjs/locale/en';

(function () {
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);

    const localeList = dayjs.Ls;

    dayjs.updateLocale('de', {
        relativeTime: {
            ...localeList['de'].relativeTime,
            s: function (number, withoutSuffix, key, isFuture) {
                if (withoutSuffix) {
                    return '1 Minute';
                }

                return 'weniger als 1 Minute';
            },
        },
    });
    dayjs.updateLocale('en', {
        relativeTime: {
            ...localeList['en'].relativeTime,
            s: function (number, withoutSuffix, key, isFuture) {
                if (withoutSuffix) {
                    return '1 minute';
                }

                if (isFuture) {
                    return 'in less than 1 minute';
                }

                return 'less than 1 minute';
            },
        },
    });
    window.dayjs = dayjs;
})();

require('./timeframe');
