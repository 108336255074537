export function navigateTab(id = undefined) {
    const fragment = window.location.hash;
    if (!fragment) {
        // No tab to preselect
        return;
    }

    let tabViews = getTabViews(id, fragment);

    if (tabViews.length === 0) {
        // No tab views
        return;
    }

    for (const { activeTab, view } of tabViews) {
        if (!activeTab) {
            continue;
        }

        $(activeTab).tab('show');
        openParentTabs(activeTab);
    }
}

function getTabViews(id, fragment)
{
    let views = $('[role=tablist]');

    if (id) {
        views = $(id).find('[role=tablist]');
    }

    return Array.from(views)
        .map((view) => ({
            view,
            tabs: Array.from($(view).find('[role=tab]')),
        }))
        .filter(({tabs}) => tabs.length > 0)
        .map(({view, tabs}) => ({
            view,
            tabs,
            activeTab: tabs.find((tab) => (new URL(tab.href)).hash === fragment),
        }));
}

function openParentTabs(tab) {
    let parentTabPanels = Array.from($(tab).parents('[role=tabpanel]'));

    if (parentTabPanels.length === 0) {
        return;
    }

    let parentTabs = parentTabPanels.map((panel) => $(`#${$(panel).attr('aria-labelledby')}`));
    parentTabs.forEach((tab) => $(tab).tab('show'));
}
