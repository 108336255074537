import { ajax } from 'rxjs/ajax';
import {
    map,
    tap,
    switchMap,
    delay,
    of,
    filter,
    pipe,
    combineLatest,
    first,
    Observable,
    Subject,
    mergeMap,
    fromEventPattern,
    EMPTY,
    takeUntil,
    take,
} from 'rxjs';
import { VideoPlayerType } from '../models/video-player-type';

export class CoinService {
    #destroy$ = new Subject();

    constructor() {
        $(window).on('beforeunload', () => this.destroy());
    }

    /**
     * Lifecycle hook destroy
     */
    destroy() {
        this.#destroy$.next();
        this.#destroy$.complete();
    }

    /**
     * Register a new coin event
     *
     * @param {string} modelType
     * @param {number} modelId
     * @param {Object<any>|Array<any>} additional
     * @param {string} event
     * @param {string|null|undefined} eventType
     * @returns {Observable<any>}
     */
    registerEvent(modelType, modelId, additional, event, eventType = null) {
        return this.#getRegisterRequest(modelType, modelId, additional, event, eventType).pipe(
            takeUntil(this.#destroy$),
        );
    }

    /**
     * Subscribe to a new coin event
     *
     * @param {string} modelType
     * @param {number} modelId
     * @param {Object<any>|Array<any>} additional
     * @param {string} event
     * @param {string|null|undefined} eventType
     * @param {UnaryFunction<Observable<any>, Observable<any>>} customPipe
     * @returns {Observable<any>}
     */
    subscribeEvent(modelType, modelId, additional, event, eventType = null, customPipe = pipe()) {
        return this.#getSubscribeRequest(modelType, modelId, additional, event, eventType).pipe(
            takeUntil(this.#destroy$),
            filter(({transactionId}) => !!transactionId),
            customPipe,
            switchMap(({transactionId}) => this.#getFinishRequest(transactionId)),
        );
    }

    /**
     * Subscribe to a new coin event (video)
     *
     * @param {string} modelType
     * @param {number} modelId
     * @param {Object<any>|Array<any>} additional
     * @param {string} event
     * @param {string|undefined|null} eventType
     * @param {HTMLElement} videoPlayer
     * @param {VideoPlayerType} videoPlayerType
     */
    subscribeVideoEvent(modelType, modelId, additional, event, eventType, videoPlayer, videoPlayerType) {
        // Currently only Youtube
        const state$ = new Subject();
        const videoPlayEvent = state$.pipe(
            filter(({data: state}) => state === YT.PlayerState.PLAYING),
            take(1),
            map(({target}) => target.playerInfo.videoData.video_id),
        );
        const videoEndEvent = state$.pipe(
            filter(({data: state}) => state === YT.PlayerState.ENDED),
            take(1),
            map(({target}) => target.playerInfo.videoData.video_id),
        );

        if (videoPlayerType.name === VideoPlayerType.YouTube.name) {
            YT.ready(() => {
                new YT.Player($(videoPlayer).attr('id'), {
                    events: {
                        onStateChange: (state) => state$.next(state),
                    },
                });
            });
        }

        return videoPlayEvent.pipe(
            takeUntil(this.#destroy$),
            switchMap((videoId) => {
                return this.#getSubscribeRequest(
                    modelType,
                    modelId,
                    {
                        ...additional,
                        videoId,
                        videoSource: videoPlayerType,
                    },
                    event,
                    eventType,
                );
            }),
            switchMap((result) => videoEndEvent.pipe(
                map(() => result),
            )),
            switchMap(({transactionId}) => this.#getFinishRequest(transactionId)),
        );
    }

    /**
     * Subscribes to a new coin event (audio)
     *
     * @param {HTMLAudioElement} audioPlayer
     * @param {Array<{modelClass: string, modelId: number, src: string, event: string, eventType?: string}>} audios
     * @param {Object<any>|Array<any>} additional
     * @returns {Observable<any>}
     */
    subscribeAudioEvent(audioPlayer, audios, additional) {
        let trackedSrc = [];
        let currentSrc = null;

        const audioPlayEvent = fromEventPattern(
            (handler) => audioPlayer.addEventListener('play', handler),
        );

        const audioEndEvent = fromEventPattern(
            (handler) => audioPlayer.addEventListener('ended', handler),
        );

        return audioPlayEvent.pipe(
            takeUntil(this.#destroy$),
            tap(({target: {src}}) => currentSrc = src),
            filter(({target: {src}}) => !trackedSrc.includes(src)),
            map(({target: {src, duration}}) => {
                trackedSrc.push(src);

                const model = audios.find(({src: modelSrc}) => modelSrc === src);

                if (!model.eventType) {
                    return {
                        ...model,
                        eventType: this.#parseAudioDuration(duration),
                    }
                }

                return model;
            }),
            mergeMap(({modelClass, modelId, src, event, eventType}) => {
                return this.#getSubscribeRequest(modelClass, modelId, additional, event, eventType).pipe(
                    switchMap((result) => audioEndEvent.pipe(
                        filter(() => src === currentSrc),
                        take(1),
                        map(() => result),
                    )),
                );
            }),
            switchMap(({transactionId}) => this.#getFinishRequest(transactionId)),
        );
    }

    /**
     * Custom pipe to delay the execution
     *
     * @param {number} seconds seconds
     * @returns {UnaryFunction<Observable<any>, Observable<any>>}
     */
    pipeDelay(seconds) {
        return pipe(
            delay(seconds * 1000),
        );
    }

    /**
     * Custom pipe to wait for intersection
     *
     * @param {HTMLElement} element
     * @param {number} threshold percentage on view to trigger
     * @returns {UnaryFunction<Observable<any>, Observable<any>>}
     */
    pipeIntersection(element, threshold = 0.3) {
        const obs$ = new Observable(subscriber => {
            const intersection = new IntersectionObserver(
                entries => {
                    const { isIntersecting } = entries[0];
                    subscriber.next(isIntersecting);
                },
                {
                    threshold,
                },
            );

            intersection.observe(element);

            return {
                unsubscribe: () => intersection.disconnect(),
            }
        });

        return pipe(
            switchMap((result) => obs$.pipe(
                filter(isIntersecting => isIntersecting),
                first(),
                map(() => result),
            )),
        );
    }

    /**
     * Combination of pipeDelay and pipeIntersection
     *
     * @param {number} seconds
     * @param {HTMLElement} element
     * @param {number} threshold
     * @returns {UnaryFunction<Observable<any>, Observable<any>>}
     */
    pipeDelayAndIntersection(seconds, element, threshold = 0.3) {
        return pipe(
            switchMap((result) => combineLatest({
                delay: of(undefined).pipe(this.pipeDelay(seconds)),
                intersection: of(undefined).pipe(this.pipeIntersection(element, threshold)),
            }).pipe(map(() => result))),
        );
    }

    /**
     * Get the request observable
     *
     * @param {string} url
     * @param {object} body
     * @returns {Observable<any>}
     */
    #getRequest(url, body = {}) {
        return ajax({
            method: 'POST',
            url,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            body,
        })
        .pipe(
            first(),
            map(({response}) => response),
            tap(({toastr}) => {
                if (!toastr) {
                    return;
                }

                document.dispatchEvent(new CustomEvent("showCoinToast", { detail: toastr }));
            }),
        );
    }

    /**
     * Get the request for registering coin events
     *
     * @param {string} modelType
     * @param {number} modelId
     * @param {Object<any>|Array<any>} additional
     * @param {string} event
     * @param {string|null|undefined} eventType
     * @returns {Observable<any>}
     */
    #getRegisterRequest(modelType, modelId, additional, event, eventType = null) {
        return this.#getRequest(`/coins/register`, {
            event,
            eventType,
            model: {
                modelType,
                modelId,
            },
            additional,
        });
    }

    /**
     * Get the request for subscribing coin events
     *
     * @param {string} modelType
     * @param {number} modelId
     * @param {Object<any>|Array<any>} additional
     * @param {string} event
     * @param {string|null|undefined} eventType
     * @param {Object<any>} additional
     * @returns {Observable<any>}
     */
    #getSubscribeRequest(modelType, modelId, additional, event, eventType = null) {
        return this.#getRequest(`/coins/subscribe`, {
            event,
            eventType,
            model: {
                modelType,
                modelId,
            },
            additional,
        });
    }

    /**
     * Get the request for finishing subscribed coin events
     *
     * @param {number|undefined|null} transactionId
     * @returns {Observable<any>}
     */
    #getFinishRequest(transactionId) {
        if (transactionId === null || transactionId === undefined) {
            return EMPTY;
        }

        return this.#getRequest(`/coins/finish/${transactionId}`);
    }

    /**
     * Parses audio file duration to minutes
     *
     * @param {number} duration
     * @returns {number}
     */
    #parseAudioDuration(duration) {
        return this.#roundUpToMultiple(Math.round(duration / 60), 5, 10, 5);
    }

    /**
     * Round up an integer to the nearest multiple of multiple
     *
     * @param {number} rounding
     * @param {number} multiple
     * @param {number} max
     * @param {number} min
     * @returns {number}
     */
    #roundUpToMultiple(rounding, multiple, max, min) {
        const rounded = Math.round(rounding) % multiple === 0 ?
            Math.round(rounding) :
            Math.round((rounding + multiple / 2) / multiple) * multiple;

        if (rounded <= max && rounded >= min) {
            return rounded;
        }

        if (rounded < min) {
            return min;
        }

        return max;
    }
}
