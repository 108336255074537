document.addEventListener('alpine:init', () => {
    Alpine.data('timeFrame', function (
        date,
        locale,
        withLocaleSuffix,
        interval,
        suffix,
        appendix,
        translation,
        translationKey,
    ) {
        return {
            timer: undefined,
            observer: undefined,
            text: undefined,
            init() {
                this.text = this.getHumanReadable();
                this.observer = new IntersectionObserver(([{isIntersecting}]) => {
                    isIntersecting ? this.createInterval() : this.destroyInterval();
                });

                this.observer.observe(this.$el);
            },
            destroy() {
                this.destroyInterval();
            },
            getHumanReadable() {
                const humanReadable = window.dayjs(date).locale(locale).fromNow(!withLocaleSuffix);

                if (translation && translationKey) {
                    return translation.replace(`:${translationKey}`, humanReadable);
                }

                return `${suffix ? suffix + ' ' : ''}${humanReadable}${appendix}`;
            },
            createInterval() {
                if (this.timer) {
                    // Just to make sure that we don't have multiple running
                    this.destroyInterval();
                }

                this.text = this.getHumanReadable();
                // Create an interval which will sync with system clock
                setTimeout(() => {
                    this.timer = setInterval(() => {
                        this.text = this.getHumanReadable();
                    }, interval);
                }, 60000 - Date.now() % 60000);
            },
            destroyInterval() {
                clearInterval(this.timer);
            },
        };
    });
});
