import { CoinService } from "./coin.service";

export class CoinFacadeService {
    #service;

    constructor() {
        this.#service = new CoinService();
    }

    /**
     * Handels a coin request
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    handleRequest(request) {
        if (!request?.subscribes) {
            return this.#registerEvent(request);
        }

        if (request?.delay && request?.intersectionElement) {
            return this.#subscribeEventWithDelayAndIntersectionElement(request);
        }

        if (request?.delay) {
            return this.#subscribeEventWithDelay(request);
        }

        if (request?.videoPlayer && request?.videoPlayerType) {
            return this.#subscribeEventWithVideoPlayer(request);
        }

        if (request?.audioPlayer && request?.audioFiles?.length > 0) {
            return this.#subcribeEventWithAudioPlayer(request);
        }

        throw new Error("Invalid Coin Request");
    }

    /**
     * Register a new coin event
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    #registerEvent({modelClass, modelId, event, eventType, additional}) {
        return this.#service.registerEvent(
            modelClass,
            modelId,
            additional,
            event,
            eventType,
        );
    }

    /**
     * Subscribes to a coin event with a delay
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    #subscribeEventWithDelay({modelClass, modelId, event, eventType, delay, additional}) {
        return this.#service.subscribeEvent(
            modelClass,
            modelId,
            additional,
            event,
            eventType,
            this.#service.pipeDelay(delay),
        );
    }

    /**
     * Subscribes to a coin event with a delay and waiting for a intersection element
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    #subscribeEventWithDelayAndIntersectionElement({
        modelClass,
        modelId,
        additional,
        event,
        eventType,
        delay,
        intersectionElement,
    }) {
        return this.#service.subscribeEvent(
            modelClass,
            modelId,
            additional,
            event,
            eventType,
            this.#service.pipeDelayAndIntersection(delay, intersectionElement),
        );
    }

    /**
     * Subscribes to a coin event using a video player
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    #subscribeEventWithVideoPlayer({modelClass, modelId, event, eventType, videoPlayer, videoPlayerType, additional}) {
        return this.#service.subscribeVideoEvent(
            modelClass,
            modelId,
            additional,
            event,
            eventType,
            videoPlayer,
            videoPlayerType,
        );
    }

    /**
     * Subscribes to a coin event using an audio player
     *
     * @param {Request} request
     * @returns {Observable<any>}
     */
    #subcribeEventWithAudioPlayer({audioPlayer, audioFiles, additional}) {
        return this.#service.subscribeAudioEvent(audioPlayer, audioFiles, additional);
    }
}
